<template>
  <component :is="clinicData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No clinic found -->
    <b-alert variant="danger" :show="clinicData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'clinic-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab :clinic-data="clinicData" class="mt-2" />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormData.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton,
    // DataAddTabInformation,
    // DataAddTabSocial,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "clinic-add-new") {
      this.operationTitle = "Nouveau clinicque";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "clinic-view") {
      this.operationTitle = "Détails de la clinique";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "clinic-edit") {
      this.operationTitle = "Modification de la clinique";
      this.operationIcon = "EditIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "clinic-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let clinicData = ref(null);

    var now = new Date();

    let APP_STORE_MODULE_NAME = "app-clinic";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var defaultclinicData = ref({
      sku_clinic: "clinic-" + now.getTime(),
      sku_supervisor: "",
      name: "",
      adress: "",
      details: "",
    });
    
    if (router.currentRoute.name == "clinic-add-new") {
      // init empty clinic data
      clinicData = defaultclinicData;
    }

    if (router.currentRoute.name != "clinic-add-new") {
      // requirement
      store
        .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          clinicData.value = response.data;
        })
        .catch((error) => {
          clinicData.value = undefined;
        });
    }

    return {
      clinicData,
    };
  },
};
</script>
